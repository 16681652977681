<template>
    <div class="landing-page">
        <div class="banner-container">
            <img class="image" src="../../public/images/landing/banner.png">
        </div>
        <div class="box-container module-1 row">
            <img class="image col-md-6" src="../../public/images/landing/module-1.png">
            <div class="col-md-6 align-self-center">
                <div class="sub-text">司机用户登录在线</div>
                <div class="text">实时订单推送</div>
            </div>
        </div>
        <div class="box-container module-2 row">
            <div class="col-md-6 align-self-center">
                <div class="sub-text">APP资源分享</div>
                <div class="text">大量订单池</div>
            </div>
            <img class="image col-md-6" src="../../public/images/landing/module-2.png">
        </div>
        <div class="box-container module-3">
            <div class="title">代驾服务城市及价格</div>
            <img class="qrcode-image" src="../../public/images/qrcode.jpg">
            <div>微信扫描二维码获取价格</div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    }
}
</script>

<style type="text/css" lang="scss">
    .landing-page{
        .banner-container{
            font-size: 0;
            margin-bottom: 50px;
            .image{
                width: 100%;
            }
        }
        .box-container{
            max-width: 1280px;
            margin: 0 auto;
        }
        .module-1, .module-2{
            width: 100%;
            background-color: #fff;
            padding: 50px;
            border-radius: 20px;
            margin-bottom: 30px;
            box-sizing: border-box;
            .text{
                font-size: 70px;
                font-weight: 900;
                margin-top: 24px;
            }
            .sub-text{
                font-size: 50px;
            }
        }
        .module-3{
            background-color: #F0F0F0;
            padding: 100px 0;
            border-radius: 20px;
            margin-bottom: 30px;
            box-sizing: border-box;
            .title{
                font-size: 50px;
                color: #D43030;
                margin-bottom: 15px;
            }
            .qrcode-image{
                margin-bottom: 15px;
            }
            .tip{
                font-size: 14px;
            }
        }
    }
</style>
