import store from '../store'
import { createRouter, createWebHashHistory } from 'vue-router'
import Landing from '../views/LandingView.vue'
import Recruit from '../views/RecruitView.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: Landing
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: Recruit
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : '珠海市泰梦科技有限公司';
    // to.matched 是一个数组（匹配到是路由记录）
    // to.matched.some(record => record.meta.requiresAuth)
    if (to.meta.requiresAuth) {
        if (!store.state.user) {
        // 跳转到登录页面
            next({
                name: 'login',
                query: { // 通过 url 传递查询字符串参数
                    redirect: to.fullPath // 把登录成功需要返回的页面告诉登录页面
                }
            })
        } else {
            next() // 允许通过
        }
    } else {
        next() // 允许通过
    }
})

export default router
