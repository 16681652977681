<template>
    <div class="header-container">
        <div class="header">
            <img class="logo-image" src="../public/images/landing/logo.png">
            <div class="nav-list">
                <div class="nav" :class="{active: $route.name == 'landing'}" @click="gotoLanding">首页</div>
                <div class="nav" :class="{active: $route.name == 'recruit'}" @click="gotoRecruit">司机招募</div>
                <div class="nav" :class="{active: $route.name == 'about'}" @click="gotoAbout">关于泰梦科技</div>
            </div>
        </div>
    </div>
    <router-view/>
    <div class="footer">
        <div class="func-list">
            <div class="func" :class="{active: $route.name == 'recruit'}" @click="gotoRecruit">司机招募</div>
            <div class="func" :class="{active: $route.name == 'about'}" @click="gotoAbout">关于泰梦科技</div>
            <div class="func">常见问题</div>
            <div class="func">城市加盟合作</div>
        </div>
        <div>法律条款与平台规则</div>
        <div>© 2023 珠海市泰梦科技有限公司 版权所有</div>
        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备 2023071507 号</a>
    </div>
</template>

<script type="text/javascript">
    export default {
        data() {
            return {
                aaa: '12'
            }
        },
        methods: {
            gotoLanding() {
                this.$router.push({
                    name: 'landing'
                })
            },
            gotoRecruit() {
                this.$router.push({
                    name: 'recruit'
                })
            },
            gotoAbout() {
                this.$router.push({
                    name: 'about'
                })
            }
        }
    }
</script>

<style>
    body{
        margin: 0;
        padding: 0;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #000;
        background-color: #F7F7F7;
    }
    .header-container{
        background-color: #FFFFFF;
        .header{
            max-width: 1280px;
            height: 80px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 100px;
            box-sizing: border-box;
            .logo-image{
                width: 160px;
            }
            .nav-list{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .nav{
                    margin-left: 60px;
                    white-space: nowrap;
                    cursor: pointer;
                    &.active{
                        color: #0261CF;
                    }
                }
            }
        }
    }
    .footer{
        line-height: 30px;
        color: #808080;
        padding: 20px 0 100px;
        font-size: 16px;
        background-color: #F7F7F7;
        .func-list{
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 30px;
            max-width: 1280px;
            margin: 0 auto 20px;
            .func{
                color: #000;
                cursor: pointer;
                &.active{
                    color: #0261CF;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .header{
            padding: 0 20px !important;
            .logo-image{
                width: 100px !important;
            }
        }
    }
</style>
