<template>
    <div class="recruit-page">
        <div class="banner-container" style="position:relative;">
            <img class="image" src="../../public/images/recruit/banner.png">
        </div>
        <div class="box-container module" style="margin-top: -100px;z-index: 2;position: relative;">
            <div class="title">司机招募基本要求</div>
            <div class="condition">
                <div class="text">1、 3年安全驾驶;</div>
                <div class="text">2、年龄23-55周岁；</div>
                <div class="text">3、无违法犯罪记录、无精神病史、无吸毒史及平台认为不适合代驾的其他历史证明；</div>
                <div class="text">4、有熟练驾车经验；</div>
                <div class="text">5、 身体健康，无肢体残疾及大面积纹身；</div>
                <div class="text">6、 需提供身份证、驾驶证、本人借记卡、直系亲属联系方式，并保证前述证明材料的真实合法性。</div>
            </div>
        </div>
        <div class="box-container module row">
            <div class="title">招募流程</div>
            <div class="row">
                <div class="col-md-3">
                    <div class="pointer">1 在线报名</div>
                    <div class="complain">本人手机号注册，确认工作城市</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">2 招募答题</div>
                    <div class="complain">答题形式检验司机基础驾驶知识</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">3 信息提交</div>
                    <div class="complain">按流程提交本人身份证、驾照照片...</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">4 信息审核</div>
                    <div class="complain">对提交信息及证据进行审核（具体以注册城市分公司为准）</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">5 面试</div>
                    <div class="complain">线下一对一沟通，了解信息及解答问题</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">6 路考</div>
                    <div class="complain">线下考核司机驾驶技术</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">7 签约加盟</div>
                    <div class="complain">线下核验司机身份信息，通过后完成签约开通账号并领取司机物料</div>
                </div>
                <div class="col-md-3">
                    <div class="pointer">8 培训上线</div>
                    <div class="complain">培训司机服务相关的理论及演练服务</div>
                </div>
            </div>
        </div>
        <div class="box-container module">
            <div class="title">基础认识</div>
            <div class="row">
                <div class="col-md-6">
                    <div class="base-container" style="background-color: #FFF0F0;">
                        <div class="sub-title">软件的下载与使用</div>
                        <div class="content">签约后，可通过下载众乐从行司机端，使用签约手机号进行登陆，工作台首页点击“上线听单”按钮进行听单；接到订单后，根据服务流程在司机端进行对应操作，直至代驾服务完成。</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="base-container" style="background-color: #FFFFF0;">
                        <div class="sub-title">服务规范</div>
                        <div class="content">代驾过程中需要按照众乐从行服务规范标准进行服务，接单前准备好所需物料、保持工装整洁无异味，接单后需在30秒内联系用户并及时前往约定地点，按标准提供服务，为用户提供优质的服务体验。</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="base-container" style="background-color: #F0F7FF;">
                        <div class="sub-title">平台规则</div>
                        <div class="content">司机账号拥有12分初始代驾分，当违反平台规则时，将根据标准扣除对应的代驾分，当代驾分扣为0分时则平台将与司机解除合作。代驾分未扣至0分前，自签约日起，司机每服务满1年，将按照代驾次数进行回分，代驾分封顶12分。</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="base-container" style="background-color: #F5F0FF;">
                        <div class="sub-title">司机收入预期</div>
                        <div class="content">司机收入与订单收益挂钩，基本原则为多劳多得、不劳不得，会受城市、在线时长、上线区域等因素影响，以北京某师傅为例：月上线时间200小时，且高峰时段经常上线，月收入在9000元左右。</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {

        }
    }
}
</script>

<style type="text/css" lang="scss" scoped>
    .recruit-page{
        .banner-container{
            font-size: 0;
            margin-bottom: 50px;
            .image{
                width: 100%;
            }
        }
        .box-container{
            max-width: 1280px;
            margin: 0 auto;
        }
        .module {
            width: 100%;
            background-color: #fff;
            padding: 50px;
            border-radius: 20px;
            margin-bottom: 30px;
            box-sizing: border-box;
            .title{
                max-width: 490px;
                height: 70px;
                line-height: 70px;
                font-size: 32px;
                color: #fff;
                text-align: center;
                border-radius: 5px;
                background-color: #1441C4;
                margin: 0 auto 75px;
            }
            .condition{
                max-width: 770px;
                margin: 0 auto;
                .text{
                    font-size: 16px;
                    line-height: 60px;
                    text-align: left;
                }

            }
            .pointer {
                width: 200px;
                height: 70px;
                line-height: 70px;
                font-size: 26px;
                color: #fff;
                position: relative;
                background: #0077FF;
                margin: 0 auto 23px;
                text-indent: 30px;
            }
            .pointer:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 35px solid white;
                border-top: 35px solid transparent;
                border-bottom: 35px solid transparent;
            }
            .pointer:before {
                content: "";
                position: absolute;
                right: -35px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 35px solid #0077FF;
                border-top: 35px solid transparent;
                border-bottom: 35px solid transparent;
            }
            .complain{
                font-size: 18px;
                color: #383838;
                margin-bottom: 50px;
            }
            .text{
                font-size: 70px;
                font-weight: 900;
                margin-top: 24px;
            }
            .sub-text{
                font-size: 50px;
            }
            .base-container{
                height: 280px;
                padding: 40px;
                margin-bottom: 50px;
                .sub-title{
                    font-size: 26px;
                    font-weight: 500;
                    text-align: left;
                    margin-bottom: 30px;
                }
                .content{
                    font-size: 16px;
                    text-indent: 30px;
                    text-align: left;
                }
            }
        }
    }
</style>
